.icon-circle:before {
    mask-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.99844 0.400391C2.01021 0.400391 0.398438 2.01217 0.398438 4.00039C0.398438 5.98862 2.01021 7.60039 3.99844 7.60039C5.98666 7.60039 7.59844 5.98862 7.59844 4.00039C7.59844 2.01217 5.98666 0.400391 3.99844 0.400391Z' fill='%2310DB55'/%3E%3C/svg%3E");
}

ul li.custom-li:before {
    mask-size: 100% 100%;
    mask-repeat: no-repeat;
}

.icon-checkmark:before {
    mask-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_18975_4772)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.534 2.45481C16.1553 3.0762 16.1553 4.08368 15.534 4.70507L6.69418 13.5449C6.07279 14.1663 5.06533 14.1663 4.44394 13.5449L0.46604 9.56698C-0.155347 8.94559 -0.155347 7.93811 0.46604 7.31672C1.08743 6.69533 2.09489 6.69533 2.71628 7.31672L5.56906 10.1695L13.2837 2.45481C13.9051 1.83342 14.9126 1.83342 15.534 2.45481Z' fill='%231073FF'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_18975_4772'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.icon-rocket:before {
    mask-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.0419 1.95841L9.73186 3.96976L5.86669 7.0619V10.1337H8.93843L12.0306 6.26846L14.0419 1.95841ZM11.9173 8.92041L10.0994 11.1928L10.0993 13.7616L11.9172 11.4891L11.9173 8.92041ZM8.53117 11.7019H5.50587C4.83902 11.7019 4.2985 11.1613 4.2985 10.4945V7.46913L1.20976 7.4691C0.0684576 7.4691 -0.435683 6.0319 0.45551 5.31894L3.63004 2.77932C3.8441 2.60807 4.1101 2.51474 4.38427 2.51474L9.14134 2.51479L14.278 0.117718C15.2999 -0.359193 16.3593 0.700756 15.8826 1.72234L13.4856 6.859L13.4854 11.6158C13.4854 11.8895 13.3924 12.1556 13.2208 12.37L10.6813 15.5445C9.96832 16.4356 8.53111 15.9316 8.53111 14.7903L8.53117 11.7019ZM7.08002 4.08297L4.51085 4.08294L2.23838 5.90091L4.80755 5.90094L7.08002 4.08297Z' fill='%231073FF'/%3E%3C/svg%3E%0A");
}

html {
    overflow: hidden auto;
    scroll-behavior: smooth;
}

html[lang^='fa'],
html[lang^='ar'],
html[lang^='he'] {
    direction: rtl;
}

.overscroll-inline-contain {
    overscroll-behavior-inline: contain;
}

/* Hide scrollbar for Chrome, Safari, and Opera */

.scrollbar-hidden {
    scrollbar-width: none;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.scrollbar-hidden::-webkit-scrollbar {
    display: none;
}

@import "../../../shared/ds/src/styles/global.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  :root,
  ::backdrop {

    /**
    COMMON
        */
    --color-white: 255 255 255;
    --color-black: 31 34 40;
    --color-error: 222 42 60;
    --color-success: 44 200 117;

    /**
    INTERFACE
        */
    --color-interface-50: 255 255 255;
    --color-interface-400: 200 202 217;
    --color-interface-500: 182 183 188;
    --color-interface-600: 200 202 217;
    --color-interface-700: 123 125 135;
    --color-interface-800: 83 86 92;
    --color-interface-1000: 18 18 18;

    /**
    SURFACE
        */
    --color-surface-50: 255 255 255;
    --color-surface-50-dropdown: 255 255 255;
    --color-surface-50-input: 255 255 255;
    --color-surface-50-tooltip: 221 237 223;
    --color-surface-100: 246 246 249;
    --color-surface-100-input: 246 246 249;
    --color-surface-200: 229 229 232;
    --color-surface-150: 237 237 242;
    --color-surface-800: 36 36 37;
    --color-surface-950: 18 18 17;
    --color-surface-1000: 18 18 18;
    --color-light-surface-100: 246 246 249;
    --color-light-surface-200: 229 229 232;

    /**
    CONTROL
        */
    --color-control-50: 245 246 249;
    --color-control-100: 246 246 249;
    --color-control-150: 235 235 238;
    --color-control-200: 229 229 232;
    --color-control-250: 218 218 221;
    --color-control-600: 181 183 193;
    --color-control-650: 161 163 173;
    --color-control-950: 31 34 40;
    --color-control-1000: 18 18 18;

    /**
    BRAND
        */
    --color-brand-50: 255 255 255;
    --color-brand-100: 237 234 255;
    --color-brand-250: 237 252 241;
    --color-brand-300: 218 245 225;
    --color-brand-500: 44 220 83;
    --color-brand-550: 40 204 76;
    --color-brand-1000: 18 18 18;

    /**
    ACCENT
        */
    --color-accent-500: 44 200 117;
  }
}
